<template>
    <div class="search-component my-container px-4 text-center" v-if="perCallsStore.allGamesArray">
        <!-- Search Text Box -->
        <div class="form-group field col-12 col-md-6 m-auto mt-5" :class="{ emptySearch: searchedGames.length === 0 }, configStore.config.formStyle === 'old' ? 'old-form-group' : 'modern-form-group'">
            <input type="name" class="form-field" :placeholder="getDictionary('SEARCH_GAMES')" v-model="searchText" @input="searchText = $event.target.value" ref="searchGames" />
            <label :for="getDictionary('SEARCH_GAMES')" class="form-label" :class="{ notEmpty: searchText }">{{ getDictionary("SEARCH_GAMES") }}</label>
            <div class="col-auto text-end mt-2">
                <font-awesome-icon icon="fa-solid fa-filter" class="fa-lg cursor" @click="filter()" />
            </div>
        </div>

        <!-- Filter by game provider or feature  -->
        <div v-if="filterSection" class="my-4">
            <div class="mb-4">
                <!-- Game Providers -->
                <p class="text-start filter-searched-section-text">{{ getDictionary("PROVIDER_FILTER") }}:</p>
                <dynamic class="" componentPath="mySlider" folder="others" :sliderItems="callsStore.filteredGameProvidersArray" description="gameLogos" />
            </div>
            <div class="mb-4">
                <!-- Game Features -->
                <p class="text-start filter-searched-section-text">{{ getDictionary("FEATURE_FILTER") }}:</p>
                <dynamic class="" componentPath="mySlider" folder="others" :sliderItems="configStore.config.gameFeatures" description="gameFeatures" />
            </div>
        </div>

        <!-- Game Provider or feature search info -->
        <div v-if="studio || feature" class="my-4">
            <p class="filter-searched-section-text pb-2">
                {{ getDictionary("SEARCHED_FOR") }} <span>{{ studio }} {{ feature }}</span> {{ getDictionary("GAMES") }}
            </p>
            <dynamic v-if="studio" class="logo" :componentPath="studio" :image="true" folder="gameLogos" />
            <dynamic v-if="feature" class="logo" :componentPath="feature" :image="true" folder="gameFeatures" />
        </div>

        <!-- Number of filtered games -->
        <div class="my-container text-center" v-if="searchedGames.length > 0">
            <p class="filter-searched-section-text">{{ lobbyNumberOfGames }} {{ getDictionary("FILTERED_GAMES") }}</p>
        </div>

        <!-- Games -->
        <div class="my-row" v-if="searchedGames.length > 0">
            <template v-for="gameObject in searchedGames.slice(0, visibleNumberOfGames)" :key="gameObject.gameId">
                <game-thumbnail :game="gameObject"></game-thumbnail>
            </template>
        </div>

        <!-- load more games functionality and info -->
        <div class="col text-center mt-4" v-if="searchedGames.length > 0">
            <p class="filter-searched-section-text pb-2">{{ getDictionary("DISPLAYING") }} {{ visibleNumberOfGames }} {{ getDictionary("OF_2") }} {{ lobbyNumberOfGames }} {{ getDictionary("GAMES") }}</p>
            <div class="progress mb-3 mx-auto col-6" v-if="visibleNumberOfGames !== lobbyNumberOfGames">
                <div class="progress-bar" role="progressbar" :style="{ width: visibleNumberOfGamesPercentage }"></div>
            </div>
            <button v-if="visibleNumberOfGames !== lobbyNumberOfGames" class="skin-btn" @click="loadMoreGames()">{{ getDictionary("LOAD_MORE_GAMES") }}</button>
        </div>

        <!-- {{ perCallsStore.allGamesArray }} -->
    </div>
    <dynamic componentPath="loader" folder="others" :addSkin="true" v-else />
</template>

<script>
import GameThumbnail from "../games/gameThumbnail.vue";

export default {
    name: "search-component",
    data: function () {
        return {
            // Search related
            searchText: null,
            searchedGames: [],
            scrolledDown: false,
            studio: null,
            feature: null,
            filterSection: true,
            searchByIntervalId: null,
            // Games visibility related
            visibleNumberOfGames: 36,
            lobbyNumberOfGames: null,
            visibleNumberOfGamesPercentage: null,
        };
    },
    mounted() {
        this.myStore.setModalHeaderTitle("");
        if (this.perCallsStore.allGamesArray) {
            this.searchByFunctionality();
        } else {
            this.searchByIntervalId = setInterval(
                function () {
                    if (this.perCallsStore.allGamesArray) {
                        this.searchByFunctionality();
                        clearInterval(this.searchByIntervalId);
                    }
                }.bind(this),
                500,
            );
        }
    },
    components: {
        GameThumbnail,
    },
    props: {
        searchBy: String,
    },
    watch: {
        searchText() {
            this.searchGames();
        },
        searchBy() {
            this.searchByFunctionality();
        },
    },
    methods: {
        filter() {
            this.studio = null;
            this.feature = null;
            this.searchedGames = [];
            this.filterSection = !this.filterSection;
        },
        searchGames() {
            this.filterSection = false;
            let searchedGamesResult = [];
            if (this.searchText) {
                this.perCallsStore.allGamesArray.map((game) => {
                    if (game.name && game.gameId && game.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
                        searchedGamesResult.push(game);
                    }
                });
            }
            this.studio = null;
            this.feature = null;
            this.additionalSearchFunctionality(searchedGamesResult);
        },
        searchByFunctionality() {
            if (this.searchBy && this.searchBy.indexOf("PROVIDER_") !== -1) {
                const provider = this.searchBy.replace("PROVIDER_", "");
                this.searchGamesByProvider(provider);
            } else if (this.searchBy && this.searchBy.indexOf("FEATURE_") !== -1) {
                const feature = this.searchBy.replace("FEATURE_", "");
                this.searchGamesByFeature(feature);
            } else {
                this.$refs.searchGames.focus();
            }
        },
        searchGamesByProvider(studio) {
            this.filterSection = false;
            let searchedGamesResult = [];
            const searchText = studio === "VOLTENT" ? "WAZDAN" : studio;
            this.perCallsStore.allGamesArray.map((game) => {
                if (game.studio && game.gameId && game.studio.indexOf(searchText) !== -1) {
                    searchedGamesResult.push(game);
                }
            });
            this.feature = null;
            this.studio = studio;
            this.additionalSearchFunctionality(searchedGamesResult);
        },
        searchGamesByFeature(feature) {
            this.filterSection = false;
            let searchedGamesResult = [];
            this.perCallsStore.allGamesArray.map((game) => {
                if (game.tags && game.tags.length > 0) {
                    game.tags.some((tag) => {
                        if (tag.indexOf("FEATURES:") !== -1 && tag.toLowerCase().indexOf(feature.toLowerCase()) !== -1) {
                            searchedGamesResult.push(game);
                            return true;
                        }
                    });
                }
            });
            this.studio = null;
            this.feature = feature;
            this.additionalSearchFunctionality(searchedGamesResult);
        },
        additionalSearchFunctionality(searchedGamesResult) {
            // Calculate visibleNumberOfGames and percentage
            this.lobbyNumberOfGames = searchedGamesResult.length;
            this.visibleNumberOfGames = 36 < searchedGamesResult.length ? 36 : searchedGamesResult.length;
            let percentage = (100 / this.lobbyNumberOfGames) * this.visibleNumberOfGames;
            this.visibleNumberOfGamesPercentage = Math.round(percentage > 100 ? 100 : percentage) + "%";

            // Update searched games
            this.searchedGames = searchedGamesResult;

            // Triggering vue-lazyload to always load images (Without this the loading image was in some cases remaining visible)
            if (this.scrolledDown) {
                window.scrollBy(0, -100);
                this.scrolledDown = false;
            } else {
                window.scrollBy(0, 100);
                this.scrolledDown = true;
            }
        },
        loadMoreGames() {
            if (this.visibleNumberOfGames + 60 < this.lobbyNumberOfGames) {
                this.visibleNumberOfGames = this.visibleNumberOfGames + 60;
            } else {
                this.visibleNumberOfGames = this.lobbyNumberOfGames;
            }

            let percentage = (100 / this.lobbyNumberOfGames) * this.visibleNumberOfGames;
            this.visibleNumberOfGamesPercentage = Math.round(percentage > 100 ? 100 : percentage) + "%";
        },
    },
};
</script>

<style lang="scss">
.search-component {
    color: var(--game-hover-text);
    .form-group {
        display: block;
        transition: margin-top 500ms;
        margin-top: 0px !important;
        &.emptySearch {
            margin-top: 100px !important;
        }
    }
    .filter-searched-section-text {
        font-size: 13px;
        color: var(--form-color-lighter);
        margin-bottom: 0px !important;
        span {
            color: var(--first-color);
        }
    }
    .logo {
        fill: #fff;
        width: 140px;
    }
    .feature-logo {
        height: 100px;
        margin: 10px auto;
    }
    .skin-color {
        color: var(--first-color);
    }
}
</style>
