<template>
    <dynamic componentPath="loader" folder="others" v-if="lobby === null" />
    <div class="lobby-component my-4" v-if="lobby">
        <div class="my-row">
            <div class="col-8">
                <h3 class="brand-font">{{ getDictionary(lobby.name) }}</h3>
            </div>
            <div class="col-4 text-end">
                <router-link v-if="isLobbyRoute" class="col" to="/"><font-awesome-icon icon="fa-solid fa-house" /></router-link>
                <template v-else>
                    <router-link class="pe-2" :to="`/lobby/${lobby.id}`"><font-awesome-icon icon="fa-solid fa-eye" /></router-link>
                    <template v-if="configStore.config.homeLobbyStyle === 'netflix'">
                        <font-awesome-icon class="px-1 arrows prev-arrow" :class="`prev-gameThumbnails-${lobby.id}`" icon="chevron-up"></font-awesome-icon>
                        <font-awesome-icon class="px-1 arrows next-arrow" :class="`next-gameThumbnails-${lobby.id}`" icon="chevron-up"></font-awesome-icon>
                    </template>
                </template>
            </div>
            <div class="col-12" v-if="!isLobbyRoute && configStore.config.homeLobbyStyle === 'netflix'">
                <hr style="width: 100%; margin: 0px" />
            </div>
        </div>

        <!-- Simple Style Game Thumbnails -->
        <div class="my-row" v-if="isLobbyRoute || configStore.config.homeLobbyStyle === 'simple'">
            <template v-for="game in lobby.filteredBrandGames.slice(0, visibleNumberOfGames)" :key="game.gameId">
                <game-thumbnail :game="game"></game-thumbnail>
            </template>
        </div>
        <!-- Netflix Style Game Thumbnails -->
        <dynamic v-else-if="configStore.config.homeLobbyStyle === 'netflix'" componentPath="mySlider" folder="others" :sliderItems="lobby.filteredBrandGames.slice(0, visibleNumberOfGames)" :description="`gameThumbnails-${lobby.id}`" />

        <div class="col text-center mt-4" v-if="no_of_games === undefined">
            <p>
                <small>{{ getDictionary("DISPLAYING") }} {{ visibleNumberOfGames }} {{ getDictionary("OF") }} {{ lobbyNumberOfGames }} {{ getDictionary("GAMES") }}</small>
            </p>
            <div class="progress mb-3 mx-auto col-6" v-if="visibleNumberOfGames !== lobbyNumberOfGames">
                <div class="progress-bar" role="progressbar" :style="{ width: visibleNumberOfGamesPercentage }"></div>
            </div>
            <button v-if="visibleNumberOfGames !== lobbyNumberOfGames" class="skin-btn" @click="loadMoreGames()">{{ getDictionary("LOAD_MORE_GAMES") }}</button>
        </div>
    </div>
</template>

<script>
import GameThumbnail from "./gameThumbnail.vue";

export default {
    name: "lobby-component",
    data: function () {
        return {
            lobby: null,
            visibleNumberOfGames: 36,
            lobbyNumberOfGames: null,
            visibleNumberOfGamesPercentage: null,
            lobbyIntervalId: null,
        };
    },
    components: {
        GameThumbnail,
    },
    computed: {
        isLobbyRoute() {
            return this.$route.name === "Lobby";
        },
    },
    props: {
        //Here the underscore format was used because from Strapi CMS all html was being changed to lowercase and the props were not matching
        lobby_id: String,
        no_of_games: Number,
    },
    watch: {
        lobby_id: function () {
            this.createLobby();
        },
    },
    created() {
        this.createLobby();
    },
    methods: {
        createLobby() {
            if (this.perCallsStore.tmaLobbiesArray.length > 0) {
                this.populateLobby();
            } else {
                this.lobbyIntervalId = setInterval(
                    function () {
                        if (this.perCallsStore.tmaLobbiesArray.length > 0) {
                            this.populateLobby();
                            clearInterval(this.lobbyIntervalId);
                        }
                    }.bind(this),
                    50,
                );
            }
        },
        populateLobby() {
            this.perCallsStore.tmaLobbiesArray.some((lobby) => {
                if (lobby.id === this.lobby_id) {
                    this.lobbyNumberOfGames = lobby.filteredBrandGames.length;
                    if (this.no_of_games !== undefined) {
                        this.visibleNumberOfGames = this.no_of_games;
                    } else {
                        this.visibleNumberOfGames = 36 < this.lobbyNumberOfGames ? 36 : this.lobbyNumberOfGames;
                    }
                    let percentage = (100 / this.lobbyNumberOfGames) * this.visibleNumberOfGames;
                    this.visibleNumberOfGamesPercentage = Math.round(percentage > 100 ? 100 : percentage) + "%";

                    this.lobby = lobby;
                    return true;
                }
            });
        },
        loadMoreGames() {
            if (this.visibleNumberOfGames + 60 < this.lobbyNumberOfGames) {
                this.visibleNumberOfGames = this.visibleNumberOfGames + 60;
            } else {
                this.visibleNumberOfGames = this.lobbyNumberOfGames;
            }

            let percentage = (100 / this.lobbyNumberOfGames) * this.visibleNumberOfGames;
            this.visibleNumberOfGamesPercentage = Math.round(percentage > 100 ? 100 : percentage) + "%";
        },
    },
};
</script>
