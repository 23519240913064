import axios from "axios";
import { getUrl, getCookie } from "../helpers/helpers";
import { logOut } from "../helpers/servicesHelpers";
import * as Sentry from "@sentry/vue";

const axiosStrapiInstance = axios.create({
    baseURL: `${getUrl("strapi")}/graphql/`,
    timeout: 25000,
    headers: {
        "content-type": "application/json",
    },
});

const axiosStrapiInstanceBo = axios.create({
    baseURL: `${getUrl("strapiBo")}/graphql/`,
    timeout: 25000,
    headers: {
        "content-type": "application/json",
    },
});

const axiosTmaInstance = axios.create({
    baseURL: `${getUrl("platform")}`,
    timeout: 40000,
    headers: {
        "content-type": "application/json",
        "Accept-Language": getCookie("languageCode"),
    },
});

const openAiTranslations = (perCallsStore, myStore) => {
    let content = `Can you return me back this object only without any other text with the value translated to ${getCookie("language")}: ${JSON.stringify(perCallsStore.objectToTranslate)}`;
    const options = {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${import.meta.env.VITE_OPENAI_KEY}`,
            "Cache-Control": "public, max-age=86400"
        },
    };

    const data = {
        model: "gpt-4o-mini",
        messages: [{ role: "user", content }],
        temperature: 0.7,
    };

    return axios.post(`${import.meta.env.VITE_OPENAI_PROXY}/v1/chat/completions`, data, options).then((response) => {
        console.log("--------------");
        console.log(response.data.choices[0].message.content);
        console.log("--------------");

        if (response && response.data.choices[0].message.content) {
            try {
                perCallsStore.setObjectToTranslate("clear", JSON.parse(response.data.choices[0].message.content));
            } catch (error) {
                myStore.setAlert({ text: "GENERIC_ERROR", classes: "error" });
            }
        } else {
            myStore.setAlert({ text: "GENERIC_ERROR", classes: "error" });
        }
    });
};

const twoFA = (phoneNumber, internalServicesUrl, myStore) => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
    };

    const data = {
        mobileno: phoneNumber,
        language: getCookie("languageCode"),
    };

    return axios.post(`${internalServicesUrl}/api/loginotp.php`, data, options).then((response) => {
        if (response.status === 200 && response.data.data && response.data.data.OTP) {
            return { success: true, code: response.data.data.OTP };
        } else {
            myStore.setAlert({ text: "ERROR_TWOFA", classes: "error" });
            console.log(`twoFA - ERROR}`);
            return { success: false };
        }
    });
};

const uploadTmaFile = (file, uploadUrl, myStore) => {
    var options = {
        headers: {
            "Content-Type": file.type,
        },
    };
    return axios.put(uploadUrl, file, options).then((response) => {
        if (response.status === 200 && response.statusText === "OK") {
            return { success: true };
        } else {
            myStore.setAlert({ text: "ERROR_DOCUMENT_UPLOAD", classes: "error" });
            console.log(`uploadFile - ERROR}`);
            return { success: false };
        }
    });
};

const errorHandling = (error, myStore, requestName) => {
    // sentryError(requestName, error);
    if (error && error.code) {
        myStore.setAlert({ text: error.code, classes: "error" });
        return { success: false, message: error.code };
    } else {
        myStore.setAlert({ text: "GENERIC_ERROR", classes: "error" });
        return { success: false, message: "GENERIC_ERROR" };
    }
};

const tmaErrorHandling = (response, myStore, callsStore, router, skin, perCallsStore, requestName) => {
    if (response.data.errors && (response.data.errors[0].message.indexOf("UNAUTHENTICATED") > -1 || response.data.errors[0].message.indexOf("NOT ALLOWED") > -1 || response.data.errors[0].message.indexOf("not allowed") > -1 || response.data.errors[0].message.indexOf("FORBIDDEN") > -1)) {
        logOut(callsStore, myStore, router, false, skin, perCallsStore);
    } else if (response.data.errors && response.data.errors[0].message && (response.data.errors[0].message.indexOf("Phone") !== -1 || response.data.errors[0].message.indexOf("PHONE") !== -1)) {
        // Fix for incorrect phone message returned from TMA
        // EXPECTED VALUE OF TYPE "PHONE", FOUND "+356 89256376"; `PHONE` CANNOT REPRESENT VALUE: +356 89256376
        myStore.setAlert({ text: "INVALID_PHONE_FORMAT", classes: "warning" });
        console.log(`${requestName} - INVALID_PHONE_FORMAT`);
        return { success: false, message: "INVALID_PHONE_FORMAT" };
    } else if (response.data.errors && response.data.errors[0].message) {
        myStore.setAlert({ text: response.data.errors[0].message, classes: "warning" });
        console.log(`${requestName} - ${response.data.errors[0].message}`);
        sentryError(requestName, response);
        return { success: false, message: response.data.errors[0].message };
    }
    myStore.setAlert({ text: "GENERIC_ERROR", classes: "error" });
    return { success: false, message: "GENERIC_ERROR" };
};

const sentryError = (requestName, info) => {
    if (info) {
        Sentry.withScope(function (scope) {
            scope.setLevel("error");

            info = JSON.stringify(info);
            Sentry.setExtra(`MY_INFO`, info);
            Sentry.captureMessage(`${requestName} - Error`);
        });
    }
};

export { axiosStrapiInstance, axiosStrapiInstanceBo, axiosTmaInstance, twoFA, uploadTmaFile, errorHandling, tmaErrorHandling, openAiTranslations };
