<template>
    <div class="promotion-component" :class="isVipPage && configStore.config.vipPage ? configStore.config.vipPage.view : null">
        <div class="my-row promo-container ignore-parent-padding" :style="{ backgroundImage: `url(${promoImage}` }">
            <dynamic-button class="banner-buttons" margin="my-2" :promotionsButton="true" />
        </div>

        <template v-if="promotion.bannerSlug !== 'GameOffer'">
            <h1 class="m-4">{{ promotion.title }}</h1>
            <dynamic class="m-4" :content="promotion.shortDescription"></dynamic>
            <dynamic class="m-4" :content="promotion.promotionContent"></dynamic>
        </template>
        <template v-else-if="selectedWelcomeOffer">
            <h1 class="m-4">{{ selectedWelcomeOffer.title }}</h1>
            <dynamic class="m-4" :content="selectedWelcomeOffer.shortDescription"></dynamic>
            <dynamic class="m-4" :content="selectedWelcomeOffer.promotionContent"></dynamic>
        </template>
    </div>
</template>

<script>
import { promoOrBannerImageUrl, debounce } from "../../helpers/helpers";
export default {
    name: "promotion-component",
    props: {
        promotion: Object,
    },
    data: function () {
        return {
            promoImage: null,
            isVipPage: false,
            userLevel: null,
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler: function (newVal) {
                this.isVipPage = newVal.name.indexOf("VIP") !== -1;
            },
        },
    },
    created() {
        if(this.selectedWelcomeOffer === undefined && this.promotion.bannerSlug === 'GameOffer'){
            this.$router.push("/");
        }

        this.debounce = debounce(() => {
            this.promoOrBannerImageUrl();
        }, 100);

        this.promoOrBannerImageUrl();
        window.addEventListener("resize", this.debounce);

        setTimeout(
            function () {
                if (this.myStore.loggedIn && this.callsStore.userCampaignsList && this.callsStore.userCampaignsList.length > 0 && this.configStore.config.vipPage) {
                    this.configStore.config.vipPage.levels.map((level) => {
                        if (this.callsStore.userCampaignsList.indexOf(level.campaignId) !== -1) {
                            this.userLevel = level.name;
                        }
                    });
                    $(`.${this.userLevel}`).addClass("active");
                }
            }.bind(this),
            1000,
        );
    },
    computed: {
        selectedWelcomeOffer() {
            if (this.perCallsStore.promotionsObject && this.perCallsStore.promotionsObject.selectedWelcomeOffer) {
                return this.perCallsStore.promotionsObject.selectedWelcomeOffer;
            }
        },
    },
    unmounted() {
        window.removeEventListener("resize", this.debounce);
    },
    methods: {
        promoOrBannerImageUrl() {
            if (this.promotion) {
                this.promoImage = promoOrBannerImageUrl(this.promotion);
            }
        },
    },
};
</script>

<style lang="scss">
.promotion-component {
    .collapse-body {
        border: 1px solid var(--site-text-color);
        border-radius: var(--site-radius);
        padding: 10px;
    }
    .promo-container {
        position: relative;
        background-position: top center;
        height: var(--banner-image-height);
        background-size: cover;
        .banner-buttons {
            position: absolute;
            bottom: 0px;
            margin-top: -50px;
        }
    }

    span {
        color: var(--first-color);
    }

    .chevron {
        margin-left: 8px;
        transition: all 0.5s ease;
        transform: rotate(90deg);
    }
    .collapsed .chevron {
        transform: rotate(-90deg);
    }

    &.vip-column-view {
        .vip-progress {
            align-items: center;
            flex-direction: column;
            .lvl {
                text-align: center;
                border-radius: 10px;
                padding: 0 15px 10px 15px;
                border: 3px solid var(--vip-column-border-color);
                max-width: 350px;
                p {
                    margin-bottom: 0 !important;
                }
                hr {
                    margin: 10px 0;
                }
                img {
                    height: 55px;
                    padding-left: 20px;
                }
                .hat {
                    height: 90px;
                }
                &.active {
                    background-color: var(--vip-column-active-background-color);
                    color: var(--vip-column-active-color);
                    .hat {
                        filter: drop-shadow(0 0 1rem var(--vip-column-highlight-color));
                    }
                    h3 {
                        color: var(--vip-column-highlight-color);
                    }
                }
            }
            .progress {
                height: 50px;
                width: 3px;
                max-width: 50px;
                background-color: var(--vip-column-border-color);
            }
        }

        @media only screen and (min-width: $min-width-xl) {
            .vip-description {
                font-weight: bold;
                padding: 10px 80px;
                font-size: 20px;
            }
            .current-level {
                font-size: 30px;
            }
            .vip-progress {
                flex-direction: row;
                .progress {
                    height: 3px;
                    width: 100%;
                }
            }
        }
    }

    &.vip-path-view {
        .level-road {
            margin: 0 auto;
            height: 2550px;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
            max-width: 500px;
            flex-direction: column;
            position: relative;
            .lvlImage {
                position: absolute;
                width: 70px;
                cursor: pointer;
                &.lvl1 {
                    top: 100px;
                    margin-left: 40px;
                }
                &.lvl2 {
                    top: 260px;
                    margin-left: -100px;
                }
                &.lvl3 {
                    top: 360px;
                    margin-left: 10px;
                }
                &.lvl4 {
                    top: 460px;
                    margin-left: -15px;
                }
                &.lvl5 {
                    top: 660px;
                    margin-left: 10px;
                }
                &.active,
                &:hover {
                    animation: current-level-animation 2500ms ease infinite;
                }
            }
            @keyframes current-level-animation {
                // Bounce
                0%,
                20%,
                50%,
                80%,
                100% {
                    transform: translateY(0);
                }
                40% {
                    transform: translateY(-15px);
                }
                60% {
                    transform: translateY(-10px);
                }
            }

            #level-1-info,
            #level-2-info,
            #level-3-info,
            #level-4-info,
            #level-5-info {
                position: fixed;
                background: var(--site-background);
                width: 90%;
                max-width: 500px;
                top: 80px;
                left: 50%;
                transform: translate(-50%);
                color: var(--site-text-color);
                border-radius: var(--site-radius);
                border: 2px solid var(--first-color);
                z-index: 3000;
                .x {
                    width: 30px;
                    cursor: pointer;
                    margin-top: 20px;
                    span {
                        background: var(--site-text-color);
                        height: 3px;
                        border-radius: 6px;
                        &:nth-child(1) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }
                        &:nth-child(2) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }
                }
                .level-image {
                    height: 80px;
                }
                .image-info {
                    height: 55px;
                    padding-left: 20px;
                }
                visibility: hidden;
                &.visible {
                    visibility: visible;
                }
            }
        }
    }
}
</style>
