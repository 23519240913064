import { doRefreshToken, getBalances } from "../services/tma/axiosTmaRequests";
import { getPromotions, getLoggedOutCampaigns } from "../services/strapi/axiosStrapiRequests";
import { eraseCookie, getCookie, setSideMenuVisibility, setModalVisibility } from "../helpers/helpers";
import RegisterComponent from "../components/modals/register.vue";
import PromotionComponent from "../components/promotions/promotion.vue";

const includeExcludeCountryFiltration = (item, myStore, callsStore) => {
    let showItem = false;
    if (myStore.loggedIn && (item.includeInCountry === null || item.includeInCountry.indexOf(callsStore.countryCode) !== -1) && (item.excludeInCountry === null || item.excludeInCountry.indexOf(callsStore.countryCode) === -1)) {
        // Logged In -AND-
        // includeInCountry is null -OR- countryCode in includeInCountry -AND-
        // excludeInCountry is null -OR- countryCode is not in excludeInCountry
        showItem = true;
    } else if (myStore.loggedIn === false && (item.includeInCountry === null || item.includeInCountry.indexOf(callsStore.countryCode) !== -1) && (item.excludeInCountryLoggedOut === null || item.excludeInCountryLoggedOut.indexOf(callsStore.countryCode) === -1)) {
        // Logged Out -AND-
        // includeInCountry is null -OR- countryCode in includeInCountry -AND-
        // excludeInCountryLoggedOut is null -OR- countryCode is not in excludeInCountryLoggedOut
        showItem = true;
    }
    return showItem;
};

const campaignIdFiltration = (item, campaignIdList) => {
    let showItem = false;
    campaignIdList.some((campaignId) => {
        if (item.campaignId.indexOf(campaignId) !== -1) {
            showItem = true;
            return true;
        }
    });
    return showItem;
};

const setBanners = (unFilteredBannersList, myStore, callsStore, perCallsStore, campaignIdList) => {
    let filteredBanners = [];
    unFilteredBannersList.map((banner) => {
        if (includeExcludeCountryFiltration(banner.attributes, myStore, callsStore) && campaignIdFiltration(banner.attributes, campaignIdList)) {
            if (banner.attributes && banner.attributes.type === "PromotePromotion") {
                perCallsStore.setPromotePromotionObject(banner.attributes);
            } else if (banner.attributes && banner.attributes.type === "PromoteGame") {
                perCallsStore.setPromoteGameObject(banner.attributes);
            } else if (banner.attributes && banner.attributes.type === "BannerRotator") {
                filteredBanners = [...filteredBanners, banner.attributes];
            }
        }
    });
    perCallsStore.setBannerRotatorItemsList(filteredBanners);
};

const setPromotions = (router, unFilteredPromotionsList, myStore, perCallsStore, callsStore, campaignIdList) => {
    setTimeout(function () {
        let slug = "WelcomeOfferAvailable";
        if (getCookie("depositedCustomer") === "true") {
            slug = null;
        } else if (getCookie("affiliateId") !== "") {
            slug = "AffiliateWelcomeOffer";
        }

        let casinoWelcomeOffer = null;
        let liveCasinoWelcomeOffer = null;
        let sportsWelcomeOffer = null;

        let selectedWelcomeOffer = null;

        let filteredPromotions = [];

        unFilteredPromotionsList.map((promotion, index) => {
            let bannerSlug = promotion.attributes.bannerSlug;
            if (includeExcludeCountryFiltration(promotion.attributes, myStore, callsStore) && campaignIdFiltration(promotion.attributes, campaignIdList)) {
                if (slug && bannerSlug && bannerSlug === "CasinoWelcomeOffer") {
                    // Store Casino welcome offer in case affiliate Code in promotion does not match cookie affiliate id
                    casinoWelcomeOffer = promotion.attributes;
                } else if (slug && bannerSlug && bannerSlug === "LiveCasinoWelcomeOffer") {
                    // Store LiveCasinoWelcomeOffer in case affiliate Code in promotion does not match cookie affiliate id
                    liveCasinoWelcomeOffer = promotion.attributes;
                } else if (slug && bannerSlug && bannerSlug === "SportsWelcomeOffer") {
                    // Store SportsWelcomeOffer in case affiliate Code in promotion does not match cookie affiliate id
                    sportsWelcomeOffer = promotion.attributes;
                }

                addRoute(router, promotion, index, "promotion");
                if (promotion.attributes.bannerSlug === "GameOffer") {
                    addRoute(router, promotion, index, "register");
                }

                if (bannerSlug && bannerSlug === "GameOffer") {
                    // Remove GameOffers from Promotion list
                } else if (slug === null && bannerSlug && (bannerSlug === "CasinoWelcomeOffer" || bannerSlug === "AffiliateWelcomeOffer" || bannerSlug === "LiveCasinoWelcomeOffer" || bannerSlug === "SportsWelcomeOffer")) {
                    // Remove CasinoWelcomeOffer, AffiliateWelcomeOffer, LiveCasinoWelcomeOffer and SportsWelcomeOffer if user already deposited
                } else if (slug === "WelcomeOfferAvailable" && bannerSlug && bannerSlug === "AffiliateWelcomeOffer") {
                    // Remove any affiliate Welcome Offers if bannerSlug === "WelcomeOfferAvailable" (User landed with no affiliateId)
                } else if (slug === "AffiliateWelcomeOffer" && bannerSlug && bannerSlug === "AffiliateWelcomeOffer" && promotion.attributes.affiliateCode.indexOf(getCookie("affiliateId")) === -1) {
                    // Remove any affiliate Welcome Offers if bannerSlug === "AffiliateWelcomeOffer" but Affiliate Id does not match (User affiliate id is not the same as the promotion affiliate id)
                } else {
                    filteredPromotions = [...filteredPromotions, promotion];
                }

                if (slug === "AffiliateWelcomeOffer" && bannerSlug && bannerSlug === slug) {
                    if (promotion.attributes.affiliateCode.indexOf(getCookie("affiliateId")) === -1) {
                        // Affiliate Code in promotion does not match cookie affiliate id
                    } else {
                        // Affiliate Code matches so we set the selectedWelcomeOffer to the Affiliate Offer
                        selectedWelcomeOffer = promotion.attributes;
                    }
                }
            }
        });

        if (slug === "AffiliateWelcomeOffer" && selectedWelcomeOffer === null) {
            // In case we dont have a promotion for this particular affiliate (We show the default welcome offer)
            selectedWelcomeOffer = casinoWelcomeOffer; // If we have a dedicated casino and liveCasino welcome offer this could be null.
        } else if (slug === "AffiliateWelcomeOffer" && selectedWelcomeOffer !== null) {
            // But if we found a dedicated promotion for the affiliate we remove the default welcome offers from the list
            filteredPromotions.some((promo, index) => {
                if (promo.attributes.bannerSlug === "CasinoWelcomeOffer") {
                    filteredPromotions.splice(index, 1);
                }
            });
            filteredPromotions.some((promo, index) => {
                if (promo.attributes.bannerSlug === "LiveCasinoWelcomeOffer") {
                    filteredPromotions.splice(index, 1);
                }
            });
            filteredPromotions.some((promo, index) => {
                if (promo.attributes.bannerSlug === "SportsWelcomeOffer") {
                    filteredPromotions.splice(index, 1);
                }
            });
        }

        if (selectedWelcomeOffer === null) {
            // If the selectedWelcomeOffer is still null, means that it's not an affiliate user or we don't have a didicated affiliate offer for this user.
            perCallsStore.setPromotionsObject({ selectedWelcomeOffer: casinoWelcomeOffer, liveCasinoWelcomeOffer, sportsWelcomeOffer, promotions: filteredPromotions });
        } else {
            perCallsStore.setPromotionsObject({ selectedWelcomeOffer, promotions: filteredPromotions });
        }
        myStore.setPromotionRoutesCreated(true);
    }, 100);
};

const addRoute = (router, promotion, index, route) => {
    let parentComponent = route === "register" ? "Home" : "";
    let meta = route === "register" ? { showModal: true, loggedOut: true, narrowContentModal: true, hideHeaderModal: true } : null;

    router.addRoute(parentComponent, {
        path: `${route === "register" ? "/register/" : "/promotions/"}${promotion.attributes.websiteRoute}`,
        name: `${route === "register" ? "Register" : "Promotion"}${index} ${promotion.attributes.title}`,
        component: route === "register" ? RegisterComponent : PromotionComponent,
        props: {
            promotion: promotion.attributes,
        },
        meta,
    });
};

const setPages = (router, pages, myStore) => {
    Object.keys(pages).forEach((pageKey) => {
        router.addRoute({
            path: `/pages/${pages[pageKey].websiteRoute}/:scrollId?`,
            name: pages[pageKey].title,
            component: () => import("../components/pages/page.vue"),
            props: (route) => ({ scrollId: route.params.scrollId, page: pages[pageKey] }),
        });
    });
    myStore.setPagesRoutesCreated(true);
};

const setRefreshTokenTimer = (expiresAt, refreshToken, accessToken, userId, callsStore, myStore, router, skin, perCallsStore) => {
    var differenceInMilliseconds = timeDifferenceInMilliseconds(expiresAt);

    setTimeout(function () {
        getBalances(accessToken, userId, callsStore, myStore, router, skin, perCallsStore);
        const lastNavigationTime = getCookie("lnt");
        const lastNavigationTotalBalance = getCookie("lntb");
        setTimeout(function () {
            if (lastNavigationTime !== "" || lastNavigationTotalBalance !== "") {
                // 3600sec are 1 Hour
                if (timeDifferenceInMilliseconds(lastNavigationTime) / 1000 < -3600 && lastNavigationTotalBalance === callsStore.unFormattedTotalBalance) {
                    // Inactive User
                    // Did not do any navigation for the past 1 Hour or more
                    // Balance did not change for the past 1 Hour or more
                    logOut(callsStore, myStore, router, false, skin, perCallsStore);
                } else {
                    // Active User
                    doRefreshToken(refreshToken, callsStore, myStore, router, skin, perCallsStore);
                }
            }
        }, 8000);
    }, differenceInMilliseconds - 15000);
};

const timeDifferenceInMilliseconds = (expiresAt) => {
    const currentDateAndTime = new Date();
    const expiresAtDateAndTime = new Date(expiresAt);
    return expiresAtDateAndTime.getTime() - currentDateAndTime.getTime();
};

const logOut = (callsStore, myStore, router, onReload, skin, perCallsStore) => {
    myStore.setLoggedIn(false);
    eraseCookie("accessToken");
    eraseCookie("expiresAt");
    eraseCookie("refreshToken");
    eraseCookie("userId");
    callsStore.$reset();
    setSideMenuVisibility(false);
    perCallsStore.setUserActiveBoxesArray([]);
    if (callsStore.countryCode === "fr") {
        this.callsStore.setFilterGameProvidersCommand("blockFrance");
    }
    if (onReload !== true) {
        setModalVisibility(false, router);
        myStore.setAlert({ text: "SUCCESS_LOGOUT", classes: "success" });
        setTimeout(function () {
            location.reload();
        }, 200);
    }
};

export { setBanners, setPromotions, setPages, setRefreshTokenTimer, logOut };
